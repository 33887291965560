import { transformImage } from "./imageTransformer"
import he from "he"
import { BrandNodeType } from "../model/BrandType"
import { safeHeDecode } from "../../social-supermarket/util/generalUtil"
import { BrandType } from "@social-supermarket/social-supermarket-model"

export const mapBrand = (brand: BrandNodeType): BrandType => ({
  name: safeHeDecode(brand.name || ""),
  slug: brand.slug,
  image: transformImage(brand.custom?.brandLogo || brand.image, brand.name),
  banner: transformImage(brand.banner, brand.name),
  description: brand.description,
  impactOneLine: brand.custom?.impactOneLine,
  impactIcons: brand.custom?.impactIcons,
  videoEmbed: brand.custom?.videoEmbed,
  meetTheFounder: brand.custom?.meetTheFounder,
  founderTitle: brand.custom?.founderTitle,
  founderNames: brand.custom?.founderNames,
  founderProfilePhoto: transformImage(
    brand.custom?.founderProfilePhoto,
    brand.custom?.founderNames
  ),
})

export const groupBrands = (
  brands: BrandType[],
  searchValue
): { [key: string]: BrandType[] } | {} => {
  const filterValue = searchValue.toLowerCase()

  return brands.reduce((grouped, currentBrand) => {
    if (!filterValue || currentBrand.name.toLowerCase().includes(filterValue)) {
      let group = currentBrand.name[0].toUpperCase()
      if (!grouped[group]) {
        grouped[group] = [currentBrand]
      } else {
        grouped[group].push(currentBrand)
      }
    }
    return grouped
  }, {})
}
