import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { desktop, largeDesktop, tablet } from "../../constants/screenSizes"

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;

  @media (min-width: ${desktop}px) and (max-width: ${largeDesktop}px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: ${tablet}px) {
    flex-direction: column;
  }
`

const Major = styled.div`
  width: 70%;
  padding: 0 60px;

  @media (max-width: ${largeDesktop}px) {
    padding: 0 20px;
  }

  @media (max-width: ${tablet}px) {
    width: 100%;
  }
`

const Minor = styled.div`
  width: 30%;

  @media (max-width: ${tablet}px) {
    width: 100%;
  }
`

interface Props {
  className?: string
  renderMajor: () => React.ReactNode
  renderMinor: () => React.ReactNode
}

const ColumnsLayout: FC<Props> = ({ className, renderMajor, renderMinor }) => {
  return (
    <Container className={className}>
      <Major>{renderMajor()}</Major>
      <Minor>{renderMinor()}</Minor>
    </Container>
  )
}

export default ColumnsLayout
