import Sorter from "../../../model/Sorter"
import { sortProductsByProperty } from "../../../util/productUtil"
import { ProductType } from "@social-supermarket/social-supermarket-model"

const PopularitySorter: Sorter = {
  label: "Popularity",
  id: "popularity",
  sort: (products: ProductType[]): ProductType[] => {
    return sortProductsByProperty(products, "recentSales")
  },
}

export default PopularitySorter
